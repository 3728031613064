<template>
    <div>
        <!-- 头部导航栏 -->
        <top-bar/>
        <!--主体部分-->
        <div class="login-body">
            <div class="login-content">
                <div class="login-card">
                    <h2>验证码登录</h2>
                    <div class="form-body">
                        <el-input v-model="form.phone"
                                  size="large"
                                  prefix-icon="el-icon-mobile-phone"
                                  placeholder="请输入手机号"/>
                        <el-row style="margin-top: 20px;">
                            <el-col :span="18">
                                <el-input v-model="form.code"
                                          prefix-icon="el-icon-postcard"
                                          placeholder="请输入验证码"/>
                            </el-col>
                            <el-col :span="6" style="text-align: right;">
                                <el-button class="form-button"
                                           type="text"
                                           @click.stop="getCode()"
                                           :disabled="codeIsWaiting">
                                    {{ codeText }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <div style="margin-top: 40px;">
                        <el-button v-loading="loginLoading"
                                   type="warning" style="width: 100%; font-size: 18px; height: 50px;" @click="doLogin">
                            登录
                        </el-button>
                        <p class="login-tips">
                            未注册的用户验证通过后将自动注册，登录注册代表同意
                            <router-link :to="{name: 'userAgreement'}" target="_blank"
                                         style="text-decoration: none; color: #667cff;">
                                《法教授用户协议》
                            </router-link>
                            和
                            <router-link :to="{name: 'userPrivacy'}" target="_blank"
                                         style="text-decoration: none; color: #667cff;">
                                《隐私协议》
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部版权信息 -->
        <copy-right/>
    </div>
</template>

<script>
import TopBar from "@/layout/TopBar";
import CopyRight from "@/layout/CopyRight";
import {hex_md5} from "@/util/md5"

export default {
    name: "login",
    components: {
        TopBar,
        CopyRight
    },
    data() {
        return {
            codeText: '获取验证码',
            codeIsWaiting: false,
            form: {
                phone: '',
                code: ''
            },
            loginLoading: false
        }
    },
    mounted() {
        // 如果是登录状态
        if (this.$store.state.token) {
            // 跳转首页
            this.$router.push({name: 'index'})
        }
    },
    methods: {
        // 点击登录
        doLogin() {
            if (this.form.phone.length !== 11) {
                this.$message.warning('请填写正确的手机号码')
                return false;
            }
            if (this.form.code.length !== 4) {
                this.$message.warning('请输入4位验证码');
                return false;
            }
            let form = {
                phone: this.form.phone.replace(/\s/g, ''),
                code: this.form.code,
                loginType: 0	// 0：登录，1：换手机号
            }
            this.loginLoading = true
            this.$api.post('/auth/login/loginByPhone', form).then(res => {
                this.$message.success('登录成功')
                this.$store.commit("setUserData", res.userInfo)
                this.$store.commit("setUserType", res.userInfo.userType)
                this.$store.commit("setToken", res.token)
                this.$router.push({name: 'index'})
                this.loginLoading = false
            }).catch(err => {
                this.$message.warning(err.msg || '登录失败，请稍后再试')
                this.loginLoading = false
            })
        },
        // 获取验证码
        getCode() {
            if (this.form.phone.length !== 11) {
                this.$message.warning('请填写正确的手机号码')
                return false;
            }
            this.codeText = "短信发送中..."
            this.codeIsWaiting = true

            // 向后端发送请求，发送验证码
            let key = hex_md5(this.form.phone.replace(/\s/g, '') + "fajiaoshousmskey")

            this.$api.post('/auth/login/sendCode', {
                phone: this.form.phone.replace(/\s/g, ''),
                key: key
            }).then(() => {
                // 这里此提示会被this.start()方法中的提示覆盖
                this.$message.success('验证码已发送')
                // 通知验证码组件内部开始倒计时
                this.setTimer()
            }).catch(err => {
                this.$message.warning(err.msg || '发送短信失败')
                this.codeIsWaiting = false
                this.codeText = "获取验证码"
                clearInterval(this.Timer)
            })
        },
        // 计时器
        setTimer() {
            let holdTime = 59,
                _this = this;
            _this.codeText = "重新获取(60)"
            _this.Timer = setInterval(() => {
                if (holdTime <= 0) {
                    _this.codeIsWaiting = false;
                    _this.getCodeBtnColor = "#ffffff";
                    _this.codeText = "获取验证码"
                    clearInterval(_this.Timer);
                    return;
                }
                _this.codeText = "重新获取(" + holdTime + ")"
                holdTime--;
            }, 1000)
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-input__inner {
    height: 50px;
    font-size: 16px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #DCDFE6;

    &:focus {
        border-bottom: 1.5px solid $sr-main-color;
    }
}

::v-deep .el-input__prefix {
    font-size: 17px;
    padding-top: 5px;
}

::v-deep .el-loading-spinner .path {
    stroke: $sr-main-color;
}

.login-body {
    background: url(../assets/images/login-bg.png) no-repeat center;
    background-size: cover;
    width: 100%;
    height: 800px;
    margin-bottom: -30px;

    .login-content {
        width: 1200px;
        margin: 0 auto;

        .login-card {
            background: #FFFFFF;
            width: 448px;
            min-height: 450px;
            float: right;
            margin-top: 190px;
            border-radius: 5px;
            padding: 5px 30px;

            .form-body {
                margin-top: 50px;

                .form-button {
                    font-size: 16px;
                    color: $sr-main-color;
                }
            }

            .login-tips {
                color: #a1a4b3;
                font-size: 14px;
                margin-top: 25px;
                line-height: 1.8;
            }
        }
    }
}
</style>